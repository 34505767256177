<template>
  <pro-report
    title="menu.ViewDownloadLogs"
    :columns="columns"
    v-model="list"
    row-key="id"
    auto-search
  />
</template>

<script>
import ProReport from "@/components/PROSmart/Report/ProReport";

export default {
  name: "ViewDownloadLogs",
  components: { ProReport },
  data() {
    return {
      columns: [
        {
          name: "id",
          i18NLabel: "CustMenu.Field.Id",
          field: "id",
          searchable: false,
          type: "string",
          sortable: true,
          align: "left",
        },
        {
          name: "type",
          i18NLabel: "CustMenu.Field.Type",
          field: "type",
          searchable: true,
          type: "chip",
          style: "",
          sortable: true,
          align: "left",
          list: [
            { label: "CustMenu.Field.Supplier", value: "supplier" },
            { label: "CustMenu.Field.Tenderer", value: "tenderer" },
            { label: "CustMenu.Field.Procurer", value: "procurer" },
          ],
        },
        {
          name: "userName",
          i18NLabel: "CustMenu.Field.UserName",
          field: "userName",
          searchable: true,
          type: "string",
          style: "",
          sortable: true,
          align: "left",
        },
        {
          name: "categoryId",
          i18NLabel: "CustMenu.Field.CategoryId",
          field: "CategoryId",
          searchable: true,
          type: "number",
          style: "width: 140px",
          sortable: true,
          align: "left",
        },
        {
          name: "category",
          i18NLabel: "CustMenu.Field.Category",
          field: "category",
          searchable: true,
          type: "string",
          style: "",
          sortable: true,
          align: "left",
        },
        {
          name: "fileId",
          i18NLabel: "CustMenu.Field.FileId",
          field: "fileId",
          searchable: true,
          type: "string",
          style: "",
          sortable: true,
          align: "left",
        },
        {
          name: "fileName",
          i18NLabel: "CustMenu.Field.FileName",
          field: "fileName",
          searchable: true,
          type: "string",
          style: "",
          sortable: true,
          align: "left",
        },
        {
          name: "remark",
          i18NLabel: "CustMenu.Field.Remark",
          field: "remark",
          searchable: true,
          type: "string",
          style: "",
          sortable: true,
          align: "left",
        },
        {
          name: "dateCreated",
          align: "left",
          i18NLabel: "CustMenu.Field.dateCreated",
          field: "dateCreated",
          searchable: true,
          sortable: true,
          type: "datetime",
          style: "width: 300px",
          convertTo: "datetime",
        },
      ],
      list: [],
    };
  },
  mounted() {
    this.$proSmart.documentUi
      .getDownloadLogList(this, this.$route.params.id)
      .then((b) => {
        this.list = b.map((item) => {
          if (item.tendererId) {
            item.type = "supplier";
          } else if (item.procurerId) {
            item.type = "tenderer";
          } else {
            item.type = "procurer";
          }

          return item;
        });
      });
  },
};
</script>
